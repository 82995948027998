// *************************************
// IMPORT VARIABLE OVERRIDES
// *************************************
@import "functions";
@import "mixins";
@import "variables";

// *************************************
// FONTS
// *************************************
@import "~@mdi/font/scss/variables";
@import "~@mdi/font/scss/functions";
@import "~@mdi/font/scss/core";
@import "~@mdi/font/scss/icons";
@font-face {
	font-family: '#{$mdi-font-name}';
	src: url('#{$mdi-font-path}/#{$mdi-filename}-webfont.eot?v=#{$mdi-version}');
	src: url('#{$mdi-font-path}/#{$mdi-filename}-webfont.eot?#iefix&v=#{$mdi-version}') format('embedded-opentype'),
	url('#{$mdi-font-path}/#{$mdi-filename}-webfont.woff2?v=#{$mdi-version}') format('woff2'),
	url('#{$mdi-font-path}/#{$mdi-filename}-webfont.woff?v=#{$mdi-version}') format('woff'),
	url('#{$mdi-font-path}/#{$mdi-filename}-webfont.ttf?v=#{$mdi-version}') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@import url("//fonts.googleapis.com/css2?family=Martel:wght@300;600;700&display=swap");

// *************************************
// BOOTSTRAP
// *************************************
@import "~bootstrap/scss/bootstrap-reboot.scss";
@import "~bootstrap/scss/bootstrap-grid.scss";
@import "~bootstrap/scss/_utilities.scss";
@import "~bootstrap/scss/_type.scss";
@import "~bootstrap/scss/_images.scss";

// *************************************
// SLICK SLIDER
// *************************************
@import "~slick-carousel/slick/slick.scss";

// *************************************
// BEM CUSTOM ELEMENTS
// *************************************
@import "globals";
@import "atoms";
@import "molecules";
@import "organisms";
@import "templates";
@import "pages";
